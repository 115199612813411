'use client';

import { usePathname, useSearchParams } from 'next/navigation';
import Image from 'next/image';
import Link from 'next/link';
import { urlToTypesenseOptions } from 'sdk';
import { Typesense } from 'sdk/src/clients/typesense/react-query';
import { Title, Book, CollectionPageComponent, PageComponent, ProductViewSource } from 'types';

import TileRow from '../tile-row';
import BookTile from '../tiles/book';
import TitleTile from '../tiles/title';

import Firebase from '@/clients/firebase/firebase-react-query';

import { Links } from '../../lib/helpers/link-helper';

import styles from '~/styles/components/page-components/collection.module.scss';
import Button from '../button';

import { useGlobalState } from '~/state';
import { useMemo } from 'react';
import { lowerCaseDashes } from 'sdk';

export default function Collection(props: PageComponent<CollectionPageComponent>) {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const SHOULD_QUERY_TITLES =
    !Boolean(props.data.queryType) && !Boolean(props.data.query) && Boolean(props.data.title_ids?.length);
  const SHOULD_QUERY_BOOKS =
    !Boolean(props.data.queryType) && !Boolean(props.data.query) && Boolean(props.data.book_ids?.length);
  const SHOULD_QUERY = !Boolean(props.data.queryType) && Boolean(props.data.query);
  const SHOULD_SHOW_SELLER = props.data.queryType === 'new-listings-from-user-follows';

  const { state } = useGlobalState();
  const loggedIn = useMemo(() => !state?.user?.data?.anonymous, [state?.user]);

  // Fetch the collection query if one exists and the component was not SSR'd
  const searchQuery = useMemo(() => urlToTypesenseOptions({ url: props.data.query }), [props.data.query]);
  const queryResults = Typesense<Book | Title>().search({
    props: searchQuery,
    reactQueryOptions: {
      enabled: SHOULD_QUERY && Boolean(searchQuery?.index),
      initialData: props.data.queryResults,
    }
  });

  // Fetch books if there is no query or component was not SSR'd
  const bookResults = Firebase<Book>().fetchDataFromIds(props.data.book_ids, 'books', {
    enabled: SHOULD_QUERY_BOOKS,
    initialData: props.data.books,
  });

  // Fetch titles if there is no query or component was not SSR'd
  const titleResults = Firebase<Title>().fetchDataFromIds(props.data.title_ids, 'titles', {
    enabled: SHOULD_QUERY_TITLES,
    initialData: props.data.titles,
  });

  // get custom query if queryType exists
  const customResults = Firebase().customQuery({
    queryType: props.data.queryType,
    ...(props.data.queryProps && {
      queryProps: props.data.queryProps,
    }),
    uid: state?.user?.data?.uid,
    reactQueryOptions: {
      enabled: Boolean(props.data.queryType) && Boolean(state?.user?.data?.uid),
      ...(props.data.queryType === 'related-titles' && { staleTime: 60 * 1000 })
    },
  });

  const queryLink = Boolean(props.data.query?.length)
    ? Links.searchWithQueryAppRouter({
      pathname,
      searchParams,
      options: urlToTypesenseOptions({ url: props.data.query }),
      path: '/search',
    }).url
    : '/';

  const clickSource = useMemo(() => props?.data?.queryType ? (props?.data?.queryType + '-row') as ProductViewSource
    : props?.data?.title ? (lowerCaseDashes(props?.data?.title) as ProductViewSource) : 'collection-row', [props?.data?.queryType, props?.data?.title]);

  function titleTiles() {
    const titles: Title[] =
      customResults?.data?.type === 'titles'
        ? customResults?.data?.items
        : titleResults?.data || props.data.titles || [];
    return titles.map((title) => {
      return <TitleTile
        key={title.id}
        title={title}
        source={clickSource}
        cartStyle={props.data.cartStyle}
      />;
    });
  }

  function bookTiles() {
    const books =
      customResults?.data?.type === 'books' ? customResults?.data?.items : bookResults?.data || props.data.books || [];
    return books.map((book) => {
      if (!book.id) return;
      return <BookTile
        key={book.id}
        book={book}
        source={clickSource}
        showSeller={SHOULD_SHOW_SELLER}
        cartStyle={props.data.cartStyle}
      />;
    });
  }

  function queryTiles() {
    const results = queryResults?.data;
    if (!results?.hits?.length) return [];

    return results?.hits.map(({ document: result }) => {
      if (searchQuery?.index === 'titles') {
        return (
          <TitleTile
            key={result?.id}
            title={result as Title}
            source={clickSource}
            cartStyle={props.data.cartStyle}
          />
        );
      } else {
        return (
          <BookTile
            key={result?.id}
            book={result as Book}
            source={clickSource}
            cartStyle={props.data.cartStyle}
          />
        );
      }
    });
  }

  function tiles() {
    let allTiles;
    if (Boolean(props.data.query)) {
      allTiles = [queryTiles()].flat();
    } else {
      allTiles = [titleTiles(), bookTiles()].flat();
    }
    return !props.ssr && !allTiles.length ? null : allTiles;
  }

  function shouldShowFeaturedCollection() {
    return props.data.featured && props.data.query;
  }

  function shouldRenderTitleRow() {
    if (Boolean(props.data.queryType) && (customResults?.data?.empty || !loggedIn)) {
      return false;
    }
    return true;
  }

  function renderCollectionRow() {
    return (
      <TileRow
        description={props.data?.description}
        title={customResults?.data?.title || props.data.title} // if customResults returns a title use that, otherwise use the title from the CMS
        link={(props.data.queryType === 'shelves' || props.data.queryType === 'wishlist')
          ? { href: `/shelves/${state?.user?.data?.username}` }
          : props.data.link
            ? props.data.link
            : props.data.query
              ? { href: queryLink }
              : null}
      >
        {tiles()}
      </TileRow>
    );
  }



  function renderFeaturedCollection() {
    return (
      <Link
        passHref
        {...(props.data.link ? props.data.link : { href: queryLink })}
        legacyBehavior>
        <section className={styles.collection}>
          <div className={styles['collection-content']}>
            {props.data.title && <h1>{props.data.title}</h1>}
            {props.data.description && <h2>{props.data.description}</h2>}
            <Button text={'Show Now'} link={props.data.link?.href ? props.data.link : { href: queryLink }} />
          </div>
          <div className={styles['collection-image-container']}>
            {props.data.featuredPhoto && (
              <Image
                src={props.data.featuredPhoto}
                alt="Header image"
                priority
                unoptimized
                className={styles['collection-image-container-image']}
                fill
              />
            )}
          </div>
        </section>
      </Link>
    );
  }

  return shouldRenderTitleRow()
    ? shouldShowFeaturedCollection()
      ? renderFeaturedCollection()
      : renderCollectionRow()
    : null;
}
