'use client';

import { Title } from 'types';
import StarRating from './star-rating';
import styles from '@/styles/components/title-star-rating.module.scss';
import COLORS from '@/lib/helpers/color-helper';
import Spacer from './spacer';
import scrollToElement from '@/lib/helpers/scroll-to-element';
import { pluralize } from 'sdk';

interface TitleStarRatingProps {
  title: Title;
  type: 'full' | 'tile' | 'author';
  noSpacer?: boolean;
}
export default function TitleStarRating({ title, type, noSpacer }: TitleStarRatingProps) {
  if (!title?.avg_rating && type === 'author') {
    return <div className={styles['no-reviews']}>No reviews yet</div>;
  } 
  else if (!title?.avg_rating) {
    return <div className={styles['hidden']}></div>;
  }

  if (type === 'full') {
    return (
      <>
        <div className={styles['full-container']}>
          <div className={styles['stars']}>
            <StarRating
              onDisabledPress={() => scrollToElement({ id: 'book-reviews' })}
              maxStars={5}
              initialRating={title.avg_rating}
              disabled
              emptyColor={COLORS.lighterGrey}
              size={22}
              enablePointer
            />
            <div className={styles['reviews']} onClick={() => scrollToElement({ id: 'book-reviews' })}>
              ({title.num_reviews})
            </div>
          </div>
          {!noSpacer && <Spacer />}
        </div>
      </>
    );
  }

  if (type === 'author') {
    return (
      <>
        <div className={styles['author-carousel-container']}>
          <div className={styles['stars']}>
            <StarRating
              maxStars={1}
              initialRating={title.avg_rating}
              disabled
              emptyColor={COLORS.lighterGrey}
              size={22}
              enablePointer
            />
            <div className={styles['rating']}>{title.avg_rating}</div>
            <div className={styles['reviews']}>
              <div>({title.num_reviews}</div>
              <div>{pluralize('review', title.num_reviews)})</div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return null; // implement the tile case
}
