'use client';

import { useRouter } from 'next/navigation';
import { Book, ProductAddedSource } from 'types';
import useCart from '@/lib/hooks/use-cart';
import Button, { ButtonProps } from '@/components/button';

interface AddToCartButtonProps extends ButtonProps {
  book: Book;
  source: ProductAddedSource;
  disabled?: boolean;
  selectedListing?: boolean;
}

export default function AddToCartButton({
  book,
  source,
  disabled,
  selectedListing,
  ...props
}: AddToCartButtonProps) {
  const router = useRouter();
  const { addToCart, isInCart } = useCart();

  async function handleAddToCart() {
    if (isInCart(book)) {
      router.push('/cart');
    } else {
      await addToCart({ item: book, source, selectedListing });
    }
  }

  return (
    <Button
      text={isInCart(book) ? 'Go to cart' : 'Add to cart'}
      onPress={handleAddToCart}
      disabled={disabled}
      {...props}
    />
  );
}
